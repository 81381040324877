import React, { useState } from 'react';
import { useLocation } from '@reach/router';
import Lottie from 'lottie-react';

import {
  Button,
  Container,
  Controller,
  FormHelperText,
  Grid,
  hookformResolver,
  InputLabel,
  schemaValidator,
  TextField,
  useForm,
} from '@tf/design-system-react';

import Template from '../../components/template/Template';
import HelmetWrapper from '../../components/helmet-wrapper/HelmetWrapper';
import BackGroundCover from '../../components/background-cover/BackGroundCover';
import spinningLoadingOmeletAnimation from '../../assets/json/opentrader-spinning-loading-omlet.json';

import * as styles from './token.module.scss';
import axios from 'axios';
import { getAxiosErrorMessage } from '../../utils/util';
import { copies } from '../../common/copies';
import { seo } from '../../common/seo';

interface FormDataInterface {
  password?: string;
  confirmPassword?: string;
}

const ResetTokenPage = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const mode = 'light';

  const schema = schemaValidator.object().shape({
    password: schemaValidator
      .string()
      .required(`Password is required`)
      .min(8, `Your password must be at least 8 characters long`),
    confirmPassword: schemaValidator
      .string()
      .required(`Password is required`)
      .oneOf([schemaValidator.ref('password')], `Password does not match`),
  });

  const defaultValues = {
    password: '',
    confirmPassword: '',
  };

  const location = useLocation();

  const { handleSubmit, control, formState, errors } = useForm({
    resolver: hookformResolver(schema),
    mode: 'onChange',
    defaultValues,
  });

  const redirectToApp = (): void => {
    const params = new URLSearchParams(location.search);
    const source = params.get('source');
    if (source === null || source === undefined) {
      window.location.assign('/tradefloor/#!/');
    } else {
      window.location.assign(`/tradefloor/#!/${decodeURIComponent(source)}`);
    }
  };

  const onSubmit = async (data: FormDataInterface): Promise<void> => {
    setIsLoading(true);
    const token = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    axios
      .post('/tradefloor/public_api/forgot/reset', {
        password: data.password,
        token,
      })
      .then((res) => {
        if (res.status === 200) {
          redirectToApp();
        } else {
          setIsLoading(false);
          console.error(res);
          setErrorMessage(getAxiosErrorMessage(res));
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
        setErrorMessage(getAxiosErrorMessage(error.response));
      });
  };

  return (
    <Template fullScreen={true}>
      <HelmetWrapper
        metadata={{
          seoMetaTitle: seo.password_recover.meta_title,
          seoMetaDescription: seo.password_recover.meta_description,
          seoNoIndex: null,
          seoOgTitle: seo.password_recover.og_title,
          seoOgDescription: seo.password_recover.og_description,
          seoOgType: seo.password_recover.og_type,
          seoOgImage: seo.password_recover.og_image,
        }}
      />
      <div
        data-component-name="ResetToken"
        data-component-library="ot-kontent-gatsby"
        className={styles.resetToken}
      >
        <BackGroundCover
          className={styles.headerContainer}
          mode={mode}
          background={
            'transparent linear-gradient(180deg, #FFFFFF 0%, #F8DFEB 100%) 0% 0% no-repeat padding-box'
          }
          withCurve={true}
          curveColor={'#FFFFFF4D'}
          withDecorator={true}
          decoratorColor={'#FFFFFF4D'}
          addHeaderLogo
          animatedDecorator={true}
        >
          <Container>
            <Grid container justifyContent="center" alignContent="center" direction="column">
              {isLoading ? (
                <>
                  <Grid item className={styles.header}>
                    {copies.password_changed}
                  </Grid>

                  <Grid item className={styles.divider}>
                    <hr />
                  </Grid>

                  <Grid item className={styles.message}>
                    <p>{copies.password_changed_intro}</p>
                  </Grid>

                  <Grid item className={styles.spinningLoadingOmeletAnimationContainer}>
                    <Lottie
                      className={styles.spinningLoadingOmeletAnimation}
                      animationData={spinningLoadingOmeletAnimation}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item className={styles.header}>
                    {copies.change_password}
                  </Grid>

                  <Grid item className={styles.divider}>
                    <hr />
                  </Grid>

                  <Grid item className={styles.intro}>
                    <p>{copies.change_password_intro}</p>
                  </Grid>

                  <Grid item>
                    <form className={styles.resetForm} noValidate>
                      <InputLabel>New password</InputLabel>
                      <Controller
                        as={<TextField id="password" variant="outlined" />}
                        name="password"
                        type="password"
                        control={control}
                        helperText={errors?.password?.message}
                        error={(errors?.password?.message?.length ?? 0) > 0}
                        fullWidth
                        autoFocus
                      />

                      <InputLabel>Confirm password</InputLabel>
                      <Controller
                        as={<TextField id="confirmPassword" variant="outlined" />}
                        name="confirmPassword"
                        type="password"
                        control={control}
                        helperText={errors?.confirmPassword?.message}
                        error={(errors?.confirmPassword?.message?.length ?? 0) > 0}
                        fullWidth
                        autoFocus
                      />
                      <Button
                        onClick={handleSubmit(onSubmit)}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={!formState.isValid}
                      >
                        {copies.change_password_cta}
                      </Button>

                      {errorMessage && (
                        <FormHelperText error className={styles.formHelperText}>
                          {errorMessage}
                        </FormHelperText>
                      )}
                    </form>
                  </Grid>
                </>
              )}
            </Grid>
          </Container>
        </BackGroundCover>
      </div>
    </Template>
  );
};

export default ResetTokenPage;
